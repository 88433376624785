<template>
  <div>
    <v-dialog max-width="1200px" v-model="addBottomBannerDialog"  @click:outside="closeBottomBannerDialog" :retain-focus="false" eager>
      <v-card min-height="850px">
        <v-icon
            style="top: 10px; left: 97%; position:sticky;"
            class="close__modal"
            @click="closeBottomBannerDialog"
        >mdi-close</v-icon
        >
        <v-card-title>
          <span style="text-transform:capitalize;" class="text-h5">{{ type }} modal banner</span>
        </v-card-title>
        <v-card-text style="display:flex;min-height:800px;">
          <div :style="`width: calc(100% - ${addBannerDialogTabs?0:400}px);`">
            <v-tabs v-model="addBannerDialogTabs">
              <v-tab>Styles</v-tab>
              <v-tab>Period</v-tab>
              <v-tab>Segments</v-tab>
              <v-tab-item>
                <div style="display: flex;padding:15px;">
                  <div style="display: flex;align-items: center;">
                    <label class="label" style="display: block;margin-right:8px;width:200px;margin-bottom:0;">Name</label>
                    <v-text-field
                        outlined
                        style="width:250px;"
                        dense
                        hide-details
                        v-model="addBottomBannerModel.name"
                    ></v-text-field>
                  </div>
                </div>
                <div style="display: flex;padding:15px;">
                  <div style="display: flex;align-items: center;">
                    <label class="label" style="display: block;margin-right:8px;width:200px;margin-bottom:0;">Type</label>
                    <v-select
                        flat
                        dense
                        outlined
                        style="max-width:250px;"
                        hide-details
                        :items="bannerTypes"
                        item-text="label"
                        item-value="value"
                        class="canvas__select"
                        v-model="addBottomBannerModel.type"
                    >
                      <template v-slot:selection="{ item }">
                        <div>
                          {{ item.label }}
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div>
                          {{ item.label }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
                <v-tabs v-model="selected_lang">
                  <v-tab v-for="(selected_lang, i) in selected_langs" :key="i" @click="changeLang(selected_lang)">
                    <img
                        :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                        alt=""
                        style="width: 20px; height: 15px; margin-right: 5px"
                    />
                    {{ selected_lang }}
                  </v-tab>
                  <v-tab-item v-for="lang in selected_langs" :key="lang">
                  </v-tab-item>
                </v-tabs>
                <div style="padding:15px;" v-if="addBottomBannerModel.type === 1">
                  <div style="display: flex;align-items: center;">
                    <label class="label" style="display: block;margin-right:8px;width:200px;margin-bottom:0;">Link</label>
                    <v-text-field
                        outlined
                        style="max-width:250px;"
                        dense
                        hide-details
                        v-model="addBottomBannerModel.link[landing.current_lang]"
                    ></v-text-field>
                  </div>
                </div>
                <div style="padding:15px;" v-if="addBottomBannerModel.type === 2">
                  <div style="display: flex;align-items: center;">
                    <label class="label" style="display: block;margin-right:8px;width:200px;margin-bottom:0;">Video Link</label>
                    <v-text-field
                        outlined
                        style="max-width:250px;"
                        dense
                        hide-details
                        v-model="addBottomBannerModel.video_link[landing.current_lang]"
                    ></v-text-field>
                  </div>
                </div>
                <div style="padding:15px"  v-if="addBottomBannerModel.type === 3">
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="display: block;margin-right:8px;width:200px;margin-bottom:0;">Upload video</label>
                    <v-file-input
                        accept="video/*"
                        show-size
                        ref="videoUpload"
                        style="max-width:250px;"
                        prepend-icon=""
                        outlined
                        hide-details
                        dense
                        @change="onFileChange"
                    ></v-file-input></div>
                </div>
                <div style="padding:15px;" v-if="addBottomBannerModel.type !== 3">
                  <div>
                    <label class="label" style="display:block;margin-bottom:15px;">Image</label>
                    <div style="margin-bottom: 15px;max-width:100%;">
                      <vue-dropzone
                          id="upload"
                          ref="upload"
                          :options="dropzoneOptions"
                          :maxFiles="1"
                          :destroyDropzone="false"
                          @vdropzone-mounted="mountDropzone"
                          @vdropzone-sending="customEvent"
                          @vdropzone-removed-file="handleRemove"
                          @vdropzone-success="handleSuccess"
                      >
                      </vue-dropzone>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <label class="label" style="font-weight:bold;">General Settings:</label>
                  </div>
                  <div style="padding:15px;">
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="display:block;min-width:200px;margin-bottom:0;">Width <b>{{addBottomBannerModel.styles.width}} %</b></label>
                      <div class="control" style="width:100%;">
                        <div class="slider">
                          <v-slider
                              hide-details
                              :min="10"
                              :max="100"
                              v-model="addBottomBannerModel.styles.width"
                          ></v-slider>
                        </div>
                      </div>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="display:block;min-width:200px;margin-bottom:0;">Height <b>{{addBottomBannerModel.styles.height}} %</b></label>
                      <div class="control" style="width:100%;">
                        <div class="slider">
                          <v-slider
                              hide-details
                              :min="10"
                              :max="100"
                              v-model="addBottomBannerModel.styles.height"
                          ></v-slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="addBottomBannerModel.type === 2">
                  <div>
                    <label class="label" style="font-weight:bold;">Video Icon Settings:</label>
                  </div>
                  <div style="padding:15px;">
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="display:block;min-width:200px;margin-bottom:0;">Size <b>{{addBottomBannerModel.styles.banner_video_icon_size}} px</b></label>
                      <div class="control" style="width:100%;">
                        <div class="slider">
                          <v-slider
                              hide-details
                              :min="10"
                              :max="100"
                              v-model="addBottomBannerModel.styles.banner_video_icon_size"
                          ></v-slider>
                        </div>
                      </div>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="display:block;margin-right:8px;margin-bottom: 0;width:200px">Color</label>
                      <v-menu
                          v-model="addBottomBannerModelIconColor"
                          top
                          nudge-bottom="98"
                          nudge-left="16"
                          :close-on-content-click="false"
                          z-index="212"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="`background-color:${addBottomBannerModel.styles.banner_video_icon_color}`" class="color" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                                v-model="addBottomBannerModel.styles.banner_video_icon_color"
                                flat
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </div>
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="display:block;margin-right:8px;margin-bottom: 0;width:200px">Color Hover</label>
                      <v-menu
                          v-model="addBottomBannerModelIconColorHover"
                          top
                          nudge-bottom="98"
                          nudge-left="16"
                          :close-on-content-click="false"
                          z-index="212"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="`background-color:${addBottomBannerModel.styles.banner_video_icon_color_hover}`" class="color" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                                v-model="addBottomBannerModel.styles.banner_video_icon_color_hover"
                                flat
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </div>

                  </div>
                </div>
              </v-tab-item>
              <v-tab-item style="padding: 15px;">
                <div style="display: flex;flex:1;align-items: center;margin-bottom:15px;">
                  <label class="label" style="min-width:250px;display:block;margin-right:15px;margin-bottom:0;">Change date period</label>
                  <DatePicker
                      :shortcuts="shortcuts"
                      format="DD.MM.YYYY"
                      v-model="addBottomBannerModel.dates"
                      type="date"
                      :disabled="allDates"
                      style="max-width:320px;"
                      @change="changeDates"
                      range
                      placeholder="Select date period"
                  >
                    <template v-slot:input>
                      <v-text-field
                          solo
                          flat
                          outlined
                          :disabled="allDates"
                          dense
                          style="max-width:320px;"
                          :placeholder="$t('statistics[\'Период\']')"
                          readonly
                          hide-details
                          v-model="addBottomBannerModel.date_model"
                      ></v-text-field>
                    </template>
                  </DatePicker>
                  <label class="label" style="margin:0 15px;">Show all time</label><v-switch hide-details style="margin:0;padding:0;" v-model="allDates" @change="setShowAllTime"></v-switch>
                </div>
                <div style="display: flex;flex:1;align-items: center;margin-bottom:15px;">
                  <label class="label" style="min-width:250px;display:block;margin-right:15px;margin-bottom:0;">Show per day</label>
                  <v-text-field type="number" min="1"
                                style="max-width:320px;"
                                solo
                                flat
                                outlined
                                dense
                                hide-details v-model.number=addBottomBannerModel.styles.show_per_day></v-text-field>
                </div>
                <div style="padding:0;margin-bottom:15px;display:flex;align-items:center;">
                  <label class="label" style="min-width:250px;display:block;margin-right:15px;margin-bottom:0;">Show not limited per time</label>
                  <v-switch style="margin:0;padding:0;" hide-details v-model="allTimes" @change="changeAllWeek"></v-switch>
                </div>
                <div v-if="!allTimes" style="display: flex;margin-top:15px;width:100%;">
                  <div style="display: flex;flex-direction: column;width:100%;">
                    <div v-for="(times,i) in addBottomBannerModel.week" :key="i" style="border-bottom:1px solid #ccc;display: flex;flex-direction: column;justify-content: center;margin-bottom:10px;width:100%;">
                      <div style="display: flex;padding:10px;justify-content: space-between;">
                        <div>
                          <label class="label">{{days[i]}}</label>
                        </div>
                        <div style="display: flex;flex-direction: column;padding:15px;">
                          <div style="display:flex;margin-bottom:5px;padding:5px;align-items: center;" v-for="(time,index) in indexArr(times)" :key="index+'start'" >
                            <div style="display: flex;flex-direction: column;margin-right:15px;">
                              <label style="display:block;margin-bottom: 10px;">From</label>
                              <VueTimepicker hide-clear-button format="HH:mm"  v-model="times.time_start[index]"/>
                            </div>
                            <div style="display: flex;flex-direction: column;margin-right:15px;">
                              <label style="display:block;margin-bottom: 10px;">To</label>
                              <VueTimepicker hide-clear-button format="HH:mm" v-model="times.time_end[index]"/>
                            </div>
                            <v-icon @click="deletePeriod(times,index)" small style="position: relative;top:15px;" color="grey   ">mdi-close</v-icon>
                          </div>
                          <div style="display: flex;margin-bottom:5px;padding:5px;align-items:center;" v-if="!indexArr(times).length">
                            No added time period
                          </div>
                        </div>
                        <div>
                          <v-btn outlined small color="primary"  @click="addPeriod(times)"><v-icon small>mdi-plus</v-icon> add period</v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item style="padding: 15px;">
                <div style="display:flex;align-items: center;flex:1;margin-bottom:15px;">
                    <label class="label" style="display:block;margin-bottom:0;margin-right:15px;width:200px;">Selected Segments</label>
                    <v-combobox
                        hide-details
                        v-model="selectedSegments"
                        class="canvas__select"
                        item-text="name"
                        item-value="id"
                        :items="segments"
                        multiple
                        outlined
                        dense
                        flat
                    >
                    </v-combobox>
                  </div>
              </v-tab-item>
            </v-tabs>
          </div>
          <div v-if="!addBannerDialogTabs" style="position:sticky;top:0;height:100%;width:400px;border-radius:5px;background-color: rgba(0, 125, 181, 0.12);padding: 20px;">
            <label class="label" style="display: block;margin-bottom: 15px;">Modal banner preview</label>
            <div style="height:570px;min-height:570px;width:360px;max-height: 570px;max-width:360px;background: #fff;position:relative;border-radius:5px;display: flex;justify-content:center;align-items:center;flex-direction:column;">
              <div style="height:570px;width:360px;background-color:rgba(0,0,0,0.36);position:absolute;top:0;left:0;">
              </div>
              <div style="position:relative;overflow:hidden;" :style="`height:${addBottomBannerModel.styles.height}%;width:${addBottomBannerModel.styles.width}%;`   ">
                <v-icon v-if="addBottomBannerModel.type !== 3 || addBottomBannerModel.type === 3&& addBottomBannerModel.video[landing.current_lang]" style="position:absolute;cursor: pointer;z-index: 251;right:5px;top:5px;" :color="addBottomBannerModel.styles.banner_icon_color" :size="addBottomBannerModel.styles.banner_close_icon_size">{{addBottomBannerModel.styles.banner_icon}}</v-icon>
                <div class="preview" v-if="preview" :style="`position:absolute;top:0;left:0;height:${addBottomBannerModel.styles.height}%;width:${addBottomBannerModel.styles.width}%;`">
                </div>
                <img v-if="!preview&&addBottomBannerModel.type !== 3" @click="openLinkAddBanner(addBottomBannerModel.link[landing.current_lang])" :src="addBottomBannerModel.image[landing.current_lang] ? addBottomBannerModel.image[landing.current_lang] : require('@/assets/none.png')" :style="`cursor:${addBottomBannerModel.type === 1 && addBottomBannerModel.link[landing.current_lang] ? 'pointer' : 'normal'};height:100%;width:100%;`" alt="">
                <div @click="openLinkAddBanner(addBottomBannerModel.video_link[landing.current_lang])" v-if="addBottomBannerModel.type === 2 && addBottomBannerModel.video_link[landing.current_lang]" class="play-video" :style="styleObjectPlay"></div>
                <video
                    v-if="addBottomBannerModel.type === 3 && addBottomBannerModel.video[landing.current_lang]"
                    :src="addBottomBannerModel.video[landing.current_lang]"
                    playsinline
                    autoplay
                    muted
                    loop
                    :height="`${addBottomBannerModel.styles.height}%`"
                    :width="`${addBottomBannerModel.styles.width}%`"
                    style="
                      position: absolute;
                      object-fit: cover;
                      z-index: 250;
                    "
                ></video>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions style="padding: 20px 24px">
          <v-spacer></v-spacer>
          <v-btn
              class="object__submit btn btn_primary"
              color="primary"
              outlined
              @click="saveBanner"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PopupCrop title='Change image before upload?' ref="PopupCrop" @confirm="openEditor" @save="saveImg" @close="closeCrop"/>
    <PopupSuccess ref="PopupSuccess" :title="'Success!'" :content="successMessage" />
  </div>
</template>

<script>
import 'vue2-timepicker/dist/VueTimepicker.css'
import "vue2-datepicker/index.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Compressor from "compressorjs";
import Cropper from "cropperjs";
import "../../../node_modules/cropperjs/dist/cropper.min.css";
import {mapActions,mapState} from "vuex";
export default {
  name: "PopupBanner",
  props:['addBottomBannerDialog','addBottomBannerModel','type','objectItem'],
  data(){
    return{
      selectedSegments:[],
      allDates:false,
      allTimes:true,
      bannerTypes:[
        {value:0,label:"Image"},
        {value:1,label:"Image with link"},
        {value:2,label:"Image with video link"},
        {value:3,label:"Video"},
      ],
      successMessage:"",
      selected_lang:0,
      addBannerDialogTabs:0,
      days:[
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      shortcuts: [
        {
          text: "Today",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Yesterday",
          onClick() {
            const start = new Date();
            const end = new Date();
            end.setTime(end.getTime() + 1 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 7 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "Last 30 day",
          onClick() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 30 * 24 * 3600 * 1000);
            const date = [start, end];
            return date;
          },
        },
        {
          text: "This month",
          onClick() {
            const start = new Date();
            const end = new Date();
            const firstDayCurrentMonth = new Date(
                start.getFullYear(),
                start.getMonth(),
                1
            );
            const lastDayCurrefntMonth = new Date(
                end.getFullYear(),
                end.getMonth() + 1,
                0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
        {
          text: "Last month",
          onClick() {
            const dateLast = new Date();
            const firstDayCurrentMonth = new Date(
                dateLast.getFullYear(),
                dateLast.getMonth() - 1,
                1
            );
            const lastDayCurrefntMonth = new Date(
                dateLast.getFullYear(),
                dateLast.getMonth(),
                0
            );
            const date = [firstDayCurrentMonth, lastDayCurrefntMonth];
            return date;
          },
        },
      ],
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFilesize: 3,
        maxFiles: 1,
        destroyDropzone: false,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compress(file)
          }
        },
      },
      preview:false,
      addBottomBannerModelIconColorHover:false,
      addBottomBannerModelIconColor:false,
    }
  },
  async mounted(){
    if(this.$refs.upload){
      this.mountDropzone()
    }
    this.allDates = !!this.addBottomBannerModel.show_all_time;
    const candidate = this.selected_langs.find(x => x === this.landing.current_lang);
    if(candidate){
      this.selected_lang = this.selected_langs.indexOf(candidate);
    }
    await this.getSegments(this.objectItem.id);
    if(this.addBottomBannerModel.segment_ids.length){
      this.selectedSegments = JSON.parse(JSON.stringify(this.segments.reduce((acc,item) => {
        if(this.addBottomBannerModel.segment_ids.indexOf(item.id) >= 0){
          const candidate = {
            name:item.name,
            id:item.id
          };
          acc.push(candidate);
        }
        return acc;
      },[])));
    }
  },
  computed:{
    ...mapState(['landing']),
    ...mapState('landing',['selected_langs']),
    ...mapState('rooms',['roomCategories']),
    ...mapState('segments',['segments']),
    styleObjectPlay() {
      return {
        "--color":
        this.addBottomBannerModel.styles.banner_video_icon_color,
        "--color-hover": this.addBottomBannerModel.styles.banner_video_icon_color_hover,
        width: this.addBottomBannerModel.styles.banner_video_icon_size + "px",
        height: this.addBottomBannerModel.styles.banner_video_icon_size + "px",
        left: `calc(50% - ${this.addBottomBannerModel.styles.banner_video_icon_size / 2}px)`,
        top: `calc(50% - ${this.addBottomBannerModel.styles.banner_video_icon_size / 2}px)`,
      };
    },
    roomList(){
      return this.roomCategories.reduce((acc,item) => {
        const candidate = this.addBottomBannerModel.room_categories.find(x => x.id === item.id);
        if(!candidate){
          acc = [...acc,...item.rooms];
        }
        return acc;
      },[])
    },
  },
  components:{
    VueTimepicker:() => import("vue2-timepicker"),
    DatePicker:() => import("vue2-datepicker"),
    vueDropzone:() => import("vue2-dropzone"),
    PopupCrop:() => import("@/components/Popup/PopupCrop"),
    PopupSuccess:() => import("@/components/Popup/PopupSuccess")
  },
  methods:{
    ...mapActions('banners',['createBanner','editBanner']),
    ...mapActions('segments',['getSegments']),
    changeAllWeek(){
      if(this.allTimes){
        this.addBottomBannerModel.show_all_week = 1;
      }else{
        this.addBottomBannerModel.show_all_week = 0;
      }
    },
    changeLang(lang){
      this.landing.current_lang = lang;
      this.mountDropzone()
    },
    setShowAllTime(){
      if(this.allDates){
        this.addBottomBannerModel.show_all_time = 1;
      }else{
        this.addBottomBannerModel.show_all_time = 0;
      }
    },
    async saveBanner(){
      this.addBottomBannerModel.hotel_id = this.objectItem.id;
      this.addBottomBannerModel.segment_ids = this.selectedSegments.reduce((acc,item) => {
        acc.push(item.id);
        return acc;
      },[])
      if(this.type === 'Create'){
        await this.createBanner(this.addBottomBannerModel);
        this.successMessage = "You banner created successfully!";
        this.$refs.PopupSuccess.open()
        this.closeBottomBannerDialog();
      }
      if(this.type === 'Edit'){
        await this.editBanner(this.addBottomBannerModel);
        this.successMessage = "You banner created successfully!";
        this.$refs.PopupSuccess.open()
        this.closeBottomBannerDialog();
      }
    },
    closeBottomBannerDialog(){
      this.$emit('closeBottomBannerDialog');
    },
    openLinkAddBanner(link){
      if(link){
        window.open(link,'_blank')
      }
    },
    async onFileChange(file){
      try{
        if(file){
          let formData = new FormData();
          formData.append('lending_settings_id',this.landing.settings_id);
          formData.append('image',file);
          formData.append('file',file);
          const response = await this.$axios.post('upload-image',formData);
          this.addBottomBannerModel.video[this.landing.current_lang] = response.data;
        }
      }catch(e){
        console.log(e);
      }
    },
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          this.$refs.PopupCrop.open(result)
        },
        error(e) {
          console.log(e);
        }
      })
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    async saveImg(file){
      file.cropped = true;
      this.$refs.upload.dropzone.removeAllFiles();
      this.$refs.upload.dropzone.addFile(file);
      this.$refs.upload.dropzone.processQueue();
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.upload.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    openEditor(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "50%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      if (this.addBottomBannerModel.image[this.landing.current_lang]) {
        this.$refs.upload.dropzone.removeFile(file);
      }
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className =
          "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$refs.upload.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML =
          `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.upload.dropzone.removeAllFiles();
        this.$refs.upload.dropzone.addFile(newFile);
        this.$refs.upload.dropzone.processQueue();
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src =  canvas.toDataURL(file.type);
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        this.preview = false;
        document.body.removeChild(editor);
        cropper.destroy();
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });

      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.preview = true;
      let cropper = new Cropper(image, {
        preview: ".preview",
        aspectRatio: ((360/100)*+this.addBottomBannerModel.styles.width) / ((570/100)*+this.addBottomBannerModel.styles.height),
      });
    },
    closeCrop(){
      this.$refs.upload.dropzone.removeAllFiles();
    },
    customEvent(_file, xhr,formData){
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append('image',_file);
      formData.append('lending_settings_id',this.landing.settings_id);
    },
    handleRemove(){
      this.addBottomBannerModel.image[this.landing.current_lang] = "";
    },
    handleSuccess(file){
      this.addBottomBannerModel.image[this.landing.current_lang] = file.xhr.response;
    },
    deletePeriod(times,index){
      times.time_start.splice(index,1);
      times.time_end.splice(index,1);
    },
    addPeriod(day){
      const defaultItemTimeStart = {
        HH:"00",
        mm:"00"
      }
      const defaultItemTimeEnd = {
        HH:"23",
        mm:"59"
      }
      day.time_start.push(defaultItemTimeStart);
      day.time_end.push(defaultItemTimeEnd);
    },
    indexArr(times){
      return times.time_start.reduce((acc,item,index) => {
        acc.push(index)
        return acc
      },[])
    },
    changeDates() {
      if (this.addBottomBannerModel.dates[0] && this.addBottomBannerModel.dates[1]) {
        if (this.addBottomBannerModel.dates[0] > this.addBottomBannerModel.dates[1]) {
          let start = this.addBottomBannerModel.dates[0];
          let end = this.addBottomBannerModel.dates[1];
          this.addBottomBannerModel.dates[0] = end;
          this.addBottomBannerModel.dates[1] = start;
        }
      }
      this.dateRangeText();
    },
    dateRangeText() {
      let date_start = null;
      let date_end = null;
      if (this.addBottomBannerModel.dates[0] && this.addBottomBannerModel.dates[1]) {
        date_start = this.$moment(this.addBottomBannerModel.dates[0]).format("DD.MM.YYYY");
        date_end = this.$moment(this.addBottomBannerModel.dates[1]).format("DD.MM.YYYY");
      } else if (this.addBottomBannerModel.dates[0]) {
        date_start = this.$moment(this.addBottomBannerModel.dates[0]).format("DD.MM.YYYY");
      } else if (this.addBottomBannerModel.dates[1]) {
        date_end = this.$moment(this.addBottomBannerModel.dates[1]).format("DD.MM.YYYY");
      } else {
        this.addBottomBannerModel.date_model = "";
      }
      this.addBottomBannerModel.date_from = date_start;
      this.addBottomBannerModel.date_to = date_end;
      this.addBottomBannerModel.date_model = [date_start, date_end].join(" - ");
    },
    mountDropzone(){
      let elements =
          this.$refs.upload.$el.getElementsByClassName("dz-preview");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      if(this.addBottomBannerModel.image[this.landing.current_lang]){
        let mockFile = {
          accepted: true,
          cropped:true,
          kind: "image",
        };
        this.$refs.upload.manuallyAddFile(mockFile, this.addBottomBannerModel.image[this.landing.current_lang]);
        this.$refs.upload.dropzone.emit("thumbnail", mockFile, this.addBottomBannerModel.image[this.landing.current_lang]);
        this.$refs.upload.dropzone.emit("complete", mockFile);
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[0].children[0].src = this.addBottomBannerModel.image[this.landing.current_lang];
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone['previewsContainer'].children[1].children[1].style.background = 'none';
      }
    },
  }
}
</script>

<style lang="scss">
.color{
  cursor: pointer;
  height: 30px;
  width: 30px;
  border: 1px solid #000;
  border-radius: 4px;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: steelblue;
}
.play-video{
  cursor: pointer;
  -webkit-mask-image: url(~@/assets/icons/play_button.svg);
  mask-image: url(~@/assets/icons/play_button.svg);
  background-color:var(--color);
  position: absolute;
  &:hover{
    background-color:var(--color-hover);
  }
  &_with_img{
    position: absolute;
    top:150px;
    left:calc(50% - 40px);
  }
}
.dropzone .dz-preview:hover .dz-details{
  opacity: 0;
}
.canvas-menu {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 5px;
}
.canvas-menu-drag {
  display: flex;
  flex-wrap: wrap;
}

.canvas-menu_tile {
  flex-wrap: wrap;
  .canvas-menu__item {
    width: 50%;
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  .canvas-menu__link {
    flex-direction: column;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu-custom {
  width: 100%;
  & .canvas-menu__item {
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  & .canvas-menu__link {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu__item {
  margin-bottom: 10px;
}

.canvas-menu__link {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  text-decoration: none;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  color: inherit;
}

.canvas-menu__icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  &.v-icon {
    // margin-bottom: 10px;
    margin-right: 10px;
    font-size: 36px;
  }
}

.canvas-menu__text {
  color: inherit;
  text-align: center;
}

.dropzone .dz-preview .dz-details .dz-size {
  display: none;
}
.dropzone .dz-preview .dz-details .dz-filename {
  display: none;
}
.vue-dropzone > .dz-preview .dz-image {
  max-width: 150px;
  max-height: 150px;
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}.cropper-bg {
   background-repeat: repeat;
 }
.cropper-controls {
  position: absolute;
  top: 10px;
  height: 36px;
  width: 36px;
  z-index: 9999;
  border-radius: 5px;
  background: #fff;
  border: 2px solid rgb(0, 125, 181);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cropper-controls::before {
  background-color: #007aff;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.confirm-control {
  font-family: Inter, sans-serif;
  background: #fff;
  border: 2px solid rgb(0, 125, 181);
  color: #007aff;
  border-radius: 5px;
}
.confirm-control::before {
  background-color: #007aff;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.confirm-control:hover::before {
  opacity: 0.2;
}
.active-control::before {
  opacity: 0.2;
}
.dropzone .dz-preview .dz-details .dz-size {
  display: none;
}
.dropzone .dz-preview .dz-details .dz-filename {
  display: none;
}
.dropzone {
  & .dz-preview {
    & .dz-image {
      & img {
        width: 150px;
        height: 150px;
        object-fit: contain;
      }
    }
    & .dz-error-message{
      width:200px;
      top:90px;
    }
    & .dz-remove{
      margin-left: 9px;
      padding: 5px 10px;
      bottom: 8px;
    }
  }
}
</style>
